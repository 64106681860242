import { FC, MouseEvent, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { Position, Rnd } from 'react-rnd'
import i18next from 'i18next'
// import { MuiEvent } from '@microservices/wiskey-react-components/DataGrid'
import { MuiEvent } from '@microservices/wiskey-react-components'
import { Box, useTheme } from '@mui/material'

import { SavedFilterWindow } from '@pages/ConfiguredEntity/components/SearchAssistant/components/SavedFilterWindow'
import { OpenFormProps } from '@pages/ConfiguredEntity/types'
import { ConfiguredEntityWithSearchAssistant } from '@pages/ConfiguredEntityWithSearchAssistant'

import { DisplayForm } from '@components/DisplayForm'
import { FileUploadDialog } from '@components/FileUploadDialog'
import { StringEditDialog } from '@components/StringEditDialog/StringEditDialog'

import { useFetchParameterPropertiesQuery } from '@redux/api/parameters.api'
import {
  closeDialog,
  closeFormDialog,
  openFormDialog,
  openFormDialogInsteadOfView,
  setSelectedDialog,
} from '@redux/reducers/dialogWindowManager.reducer'
import { setMetaForm } from '@redux/reducers/formStates.reducer'
import { showMessage } from '@redux/reducers/snackbar.reducer'

import { DialogWindow, DraggableFormState, Size } from '@features/DialogWindowManager/DialogWindow'
import { useFormEventHandlers } from '@features/DialogWindowManager/hooks'

import { useAppDispatch, useAppSelector } from '@hooks'
import {
  getInitialPositionWindow,
  getPositionWithScroll,
  transformCommonParametersToValues,
} from '@helpers'
import {
  CLICK_EVENT_TYPE,
  COMMON_PARAMETERS,
  DRAGGABLE_FORM_SIZES,
  ENTITY,
  FORM_TYPE,
  ROUTES,
} from '@constants'
import {
  DIALOG_WINDOW_TYPE,
  DialogWindowType,
  FileUploadDialogWindowMeta,
  FormDialogWindowMeta,
  FormStateMeta,
  SavedFiltersWindowMeta,
  StringEditDialogWindowMeta,
  ViewDialogWindowMeta,
} from '@types'

import { useCopy } from '@gantt/hooks/useCopy'

const isViewDialog = (
  dialog: DialogWindowType
): dialog is DialogWindowType<ViewDialogWindowMeta> => {
  return dialog.type === DIALOG_WINDOW_TYPE.VIEW
}

const isSearchAssistantDialog = (dialog: DialogWindowType): dialog is DialogWindowType => {
  return dialog.type === DIALOG_WINDOW_TYPE.SEARCH_ASSISTANT
}

const isSavedFiltersDialog = (
  dialog: DialogWindowType
): dialog is DialogWindowType<SavedFiltersWindowMeta> => {
  return dialog.type === DIALOG_WINDOW_TYPE.SAVED_FILTERS
}

export const isFormDialog = (
  dialog: DialogWindowType
): dialog is DialogWindowType<FormDialogWindowMeta> => {
  return dialog.type === DIALOG_WINDOW_TYPE.FORM
}

const isStringEditDialog = (
  dialog: DialogWindowType
): dialog is DialogWindowType<StringEditDialogWindowMeta> => {
  return dialog.type === DIALOG_WINDOW_TYPE.STRING_EDIT
}

const isFileUploadDialog = (
  dialog: DialogWindowType
): dialog is DialogWindowType<FileUploadDialogWindowMeta> => {
  return dialog.type === DIALOG_WINDOW_TYPE.FILE_UPLOAD
}

export const DialogWindowManager: FC = () => {
  const { t } = useTranslation()
  const { dialogWindows, selectedId } = useAppSelector(state => state.dialogWindowManager)
  const formsState = useAppSelector(state => state.formStates)
  const history = useAppSelector(state => state.formHistory)

  const dispatch = useAppDispatch()

  const theme = useTheme()

  const { data: commonParameters } = useFetchParameterPropertiesQuery({
    code: COMMON_PARAMETERS.STATE,
  })

  const initialCommonParameters = useMemo(
    () => transformCommonParametersToValues(commonParameters),
    [commonParameters]
  )

  const handleCloseDialogWindow =
    (dialog: DialogWindowType) => (id: string, closeMeta: FormStateMeta, isDirty?: boolean) => {
      if (
        dialog.type === DIALOG_WINDOW_TYPE.FORM ||
        dialog.type === DIALOG_WINDOW_TYPE.SAVED_FILTERS
      ) {
        if (isDirty && !confirm(t('notifications.leave'))) {
          return
        }

        dispatch(closeFormDialog({ id }))
        dispatch(setMetaForm({ formCode: dialog.meta.formCode as string, ...closeMeta }))

        return
      }
      dispatch(closeDialog({ id }))
    }

  const handleSetSelectedDialogId = (id: string | null) => {
    dispatch(setSelectedDialog({ id }))
  }

  const handleCopyFormCode = useCopy()

  const handleOpenFormDialog = (
    dialogId: string,
    rnd: Rnd,
    state: Readonly<DraggableFormState>
  ) => {
    return (
      formProps: OpenFormProps,
      muiEvent: MouseEvent<HTMLButtonElement> | MuiEvent<MouseEvent>,
      type: CLICK_EVENT_TYPE
    ) => {
      const { id, formCode, objectCode, viewCode, objectId, path, entityType, event, viewId } =
        formProps
      const form: DialogWindowType<FormDialogWindowMeta> = {
        id,
        parentDialogId: dialogId,
        type: DIALOG_WINDOW_TYPE.FORM,
        title: null,
        meta: {
          formCode,
          objectCode,
          objectId,
          viewCode,
          path,
          event,
          globalId: id,
          entityType,
          viewId,
        },
      }
      if (
        (type === CLICK_EVENT_TYPE.ROW_CLICK && muiEvent.shiftKey) ||
        event === FORM_TYPE.CREATE // TODO: на момент написания форма создания - это блокирующая окно, в отдельном диалоговом окне.
      ) {
        const initialPosition = getInitialPositionWindow(muiEvent)
        const openedFormsCount = dialogWindows.filter(
          dialog =>
            [DIALOG_WINDOW_TYPE.FORM, DIALOG_WINDOW_TYPE.VIEW].includes(dialog.type) &&
            !dialog.hidden
        ).length
        const maxOpenedFormsCount = Number(initialCommonParameters.numberOfWindows)

        if (openedFormsCount === maxOpenedFormsCount && event !== FORM_TYPE.CREATE) {
          dispatch(
            showMessage({
              type: 'info',
              text: t('error.dynamicFormsExceeded', { count: maxOpenedFormsCount }),
            })
          )

          return
        }

        dispatch(
          openFormDialog({
            ...form,
            initialPosition,
          })
        )

        return
      }

      const viewPosition: Position = rnd.getDraggablePosition()

      const bounding = rnd.getSelfElement()?.getBoundingClientRect()
      const viewSize: Size = {
        width: bounding?.width ?? DRAGGABLE_FORM_SIZES.OPENED_WIDTH,
        height: bounding?.height ?? DRAGGABLE_FORM_SIZES.OPENED_HEIGHT,
      }

      dispatch(
        openFormDialogInsteadOfView({
          ...form,
          initialPosition: state.isFullscreen
            ? state.positionBeforeFullscreen
            : getPositionWithScroll(muiEvent, viewPosition),
          initialSize: state.isFullscreen ? state.sizeBeforeFullscreen : viewSize,
          isFullScreen: state.isFullscreen,
        })
      )
    }
  }

  const { onObjectDataRecordSuccessCreating, onBreadcrumbsMainLinkClick, ...formEventHandlers } =
    useFormEventHandlers()

  const renderChildren = (
    dialog: DialogWindowType,
    rnd: Rnd | null | undefined,
    state: Readonly<DraggableFormState>,
    onUpdateDirty: (value: boolean) => void,
    onSetWindowTitle: (title: string | null) => void
  ) => {
    if (!rnd) {
      return
    }
    if (isViewDialog(dialog)) {
      const { id, meta } = dialog

      if (!meta.viewCode) {
        return <></>
      }

      return (
        <ConfiguredEntityWithSearchAssistant
          isViewDialogWindow
          dialogId={id}
          entityCode={meta.viewCode}
          path={meta.path}
          // For rerender
          // key={menuItem.code}
          entityId={meta.viewId}
          handleOpenForm={handleOpenFormDialog(id, rnd, state)}
          title={meta.title}
          type={ENTITY.VIEW}
          windowHeight={state.storedHeight}
        />
      )
    }

    if (isFormDialog(dialog)) {
      const { id, parentDialogId, meta } = dialog

      return (
        <DisplayForm
          dialogId={id}
          samePageFormsData={undefined}
          selectedRowIds={undefined}
          skipHotKeyPress={selectedId !== id}
          windowHeight={state.storedHeight}
          windowWidth={state.storedWidth}
          passedParams={{
            formCode: meta.formCode,
            objectCode: meta.objectCode,
            objectId: meta.objectId,
            viewCode: meta.viewCode,
            // viewId?: number | string
            viewId: meta.viewId,
            path: meta.path,
            event: meta.event,
            globalId: meta.globalId,
            parentFormId: meta.parentFormId,
            openedContainers: [],
          }}
          onBreadcrumbsMainLinkClick={onBreadcrumbsMainLinkClick(id, parentDialogId, rnd, state)}
          onChangeDirty={onUpdateDirty}
          onSetWindowTitle={onSetWindowTitle}
          isFormDialogWindow
          // Обработчики
          onObjectDataRecordSuccessCreating={onObjectDataRecordSuccessCreating(id, rnd)}
          entityType={meta.entityType}
          // TODO Предзаполнение значений для форм создания (Срочная доработка с Alerts. Нужен рефакторинг)
          preFill={meta.preFill}
          {...formEventHandlers}
        />
      )
    }

    if (isSavedFiltersDialog(dialog)) {
      const { id, meta } = dialog

      return (
        <SavedFilterWindow
          dialogId={id}
          entityObjectCode={meta.entityObjectCode}
          mode={meta.mode}
          newSearch={meta.newSearch}
          searchAssistantId={meta.searchAssistantId}
          onChangeDirty={onUpdateDirty}
        />
      )
    }

    if (isSearchAssistantDialog(dialog)) {
      const { id } = dialog

      return (
        <ConfiguredEntityWithSearchAssistant
          isSearchAssistantDialogWindow
          isViewDialogWindow
          dialogId={id}
          handleOpenForm={handleOpenFormDialog(id, rnd, state)}
          path={ROUTES.SEARCH}
          title={i18next.t('searchAssistant.title')}
          type={ENTITY.VIEW}
          windowHeight={state.storedHeight}
        />
      )
    }

    if (isStringEditDialog(dialog)) {
      const { meta } = dialog

      return (
        <StringEditDialog
          heightDialog={state?.storedHeight}
          id={dialog?.id}
          readonly={meta?.readonly}
          setOuterValue={meta?.setOuterValue}
          text={meta?.text}
          widthDialog={state?.storedWidth}
        />
      )
    }

    if (isFileUploadDialog(dialog)) {
      const { id, meta } = dialog

      return <FileUploadDialog />
    }

    return <></>
  }

  return createPortal(
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        // display: 'block',
        // bottom: 0,
        // right: 0,
        // zIndex: theme => theme.zIndex.modal,
      }}
    >
      {dialogWindows.map(dialog => {
        const formCode = isFormDialog(dialog) ? dialog.meta.formCode : null
        const stateFormPosition =
          formCode && formsState[formCode] ? formsState[formCode].meta?.position : null
        const stateFormSize =
          formCode && formsState[formCode] ? formsState[formCode].meta?.size : null
        const stateFormOpen =
          formCode && formsState[formCode] ? formsState[formCode].meta?.isOpen : undefined
        const stateFormFullscreen =
          formCode && formsState[formCode] ? formsState[formCode].meta?.isFullscreen : undefined
        const stateFormSizeBeforeFullscreen =
          formCode && formsState[formCode]
            ? formsState[formCode].meta?.sizeBeforeFullscreen
            : undefined
        const stateFormPositionBeforeFullscreen =
          formCode && formsState[formCode]
            ? formsState[formCode].meta?.positionBeforeFullscreen
            : undefined
        const stateFormIsOpenBeforeFullscreen =
          formCode && formsState[formCode]
            ? formsState[formCode].meta?.isOpenBeforeFullscreen
            : undefined
        const linksData = isFormDialog(dialog) ? history[dialog.id] : null
        const currentLinkFormCode = linksData
          ? linksData.links[linksData.selectedIndex].formCode
          : null

        return (
          <DialogWindow
            key={dialog.id}
            disableDragging={false}
            formCode={currentLinkFormCode}
            hidden={dialog.hidden}
            id={dialog.id}
            initialPosition={stateFormPosition || dialog.initialPosition}
            initialSize={stateFormSize || dialog.initialSize}
            isFullScreen={stateFormFullscreen ?? dialog.isFullScreen}
            isOpen={stateFormOpen}
            isOpenBeforeFullscreen={stateFormIsOpenBeforeFullscreen}
            isSelected={selectedId === dialog.id}
            minWidth={dialog.minWidth}
            positionBeforeFullscreen={stateFormPositionBeforeFullscreen}
            sizeBeforeFullscreen={stateFormSizeBeforeFullscreen}
            theme={theme}
            title={dialog.title}
            onClose={handleCloseDialogWindow(dialog)}
            onCopyFormCode={handleCopyFormCode}
            onSetSelectedDialogId={handleSetSelectedDialogId}
          >
            {(rnd, state, onUpdateDirty, onSetWindowTitle) =>
              renderChildren(dialog, rnd, state, onUpdateDirty, onSetWindowTitle)
            }
          </DialogWindow>
        )
      })}
    </Box>,
    document.body
  )
}
