import { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query'

import { getEnrichedSortParam, transformResponseWithPagination } from '@helpers'
import { ENTITY_TYPES_REQUEST } from '@constants'
import {
  GETAllEntity,
  GETDefaultViewInSAParams,
  GETEntity,
  GETObjectData,
  GETObjectDataParams,
  GETObjectDataParamsEnriched,
  GETObjectDataRecordEnrichedParams,
  GETOptionGroupType,
  ObjectDataRecord,
  POSTCopyEntity,
  POSTEntityDTO,
  POSTOptionGroupType,
  UpdateDefaultViewInSAParams,
  ViewShortDTO,
} from '@types'

import { commonAPI } from './common.api'

export const viewApi = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchAllView: build.query<GETAllEntity, GETObjectDataParams | void>({
      query: params => ({
        url: 'ui/view',
        params: {
          page: params?.page,
          size: params?.size,
          sort: params?.sort,
          objectCode: params?.objectCode,
          searchPattern: params?.searchPattern,
          viewType: params?.viewType || ENTITY_TYPES_REQUEST.VIEW,
        },
      }),
      transformResponse: (output: ViewShortDTO[], meta: FetchBaseQueryMeta) =>
        transformResponseWithPagination<ViewShortDTO[]>(output, meta),
      providesTags: ['AllViews'],
    }),
    fetchAllFormIntegrationViews: build.query<GETAllEntity, string | void>({
      query: objectCode => ({
        url: `/ui/view/integration/${objectCode}`,
      }),
      transformResponse: (output: ViewShortDTO[], meta: FetchBaseQueryMeta) =>
        transformResponseWithPagination<ViewShortDTO[]>(output, meta),
      providesTags: ['FormIntegratedViews'],
    }),
    fetchAllViewUncertain: build.query<GETAllEntity, GETObjectDataParams | void>({
      query: params => ({
        url: 'ui/view/uncertain',
        params: {
          page: params?.page,
          size: params?.size,
          sort: params?.sort,
          viewType: params?.viewType || ENTITY_TYPES_REQUEST.VIEW,
        },
      }),
      transformResponse: (output: ViewShortDTO[], meta: FetchBaseQueryMeta) =>
        transformResponseWithPagination<ViewShortDTO[]>(output, meta),
      providesTags: ['UncertainViews'],
    }),
    fetchViewByIdNoCache: build.mutation<
      GETEntity,
      {
        id: string | number
      }
    >({
      query: ({ id }) => ({
        url: `/ui/view/id/${id}`,
        method: 'GET',
      }),
    }),
    fetchView: build.query<
      GETEntity,
      {
        code: string | void
        viewType: ENTITY_TYPES_REQUEST | string | null | void
      }
    >({
      query: ({ code, viewType }) => ({
        url: `/ui/view/code/${code}?viewType=${viewType}`,
      }),
      providesTags: ['SingleView', 'Parameter'],
    }),
    fetchViewByCode: build.query<
      GETEntity,
      {
        code: string | void
        viewType: ENTITY_TYPES_REQUEST | string | null | void
      }
    >({
      query: ({ code, viewType }) => ({
        url: `/ui/view/code/${code}?viewType=${viewType}`,
      }),
      providesTags: ['SingleView', 'Parameter', 'DefaultSA'],
    }),
    createView: build.mutation<GETEntity, POSTEntityDTO>({
      query: view => ({
        url: '/ui/view',
        method: 'POST',
        body: view,
      }),
      invalidatesTags: ['SingleView', 'UncertainViews', 'AllViews'],
    }),
    updateView: build.mutation<GETEntity, POSTEntityDTO>({
      query: view => ({
        url: '/ui/view',
        method: 'POST',
        body: view,
      }),
      invalidatesTags: ['SingleView', 'UncertainViews', 'AllViews'],
    }),
    deleteView: build.mutation<GETEntity, number | string>({
      query: id => ({
        url: `/ui/view/id/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'Menu',
        'UncertainViews',
        'GroupViews',
        'AllViews',
        'SingleForm',
        'DefaultSA',
      ],
    }),
    deleteViewByCode: build.mutation<GETEntity, string>({
      query: code => ({
        url: `/ui/view/code/${code}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Menu', 'UncertainViews', 'GroupViews', 'AllViews', 'SingleForm'],
    }),
    fetchViewGroups: build.query<
      GETOptionGroupType[],
      { viewType: ENTITY_TYPES_REQUEST | string | null | void }
    >({
      query: params => ({
        url: 'ui/group/view',
        params,
      }),
      providesTags: ['GroupViews'],
    }),
    updateViewGroups: build.mutation<
      GETOptionGroupType[],
      { groups: POSTOptionGroupType[]; viewType: ENTITY_TYPES_REQUEST | string | null | void }
    >({
      query: ({ groups, viewType }) => ({
        url: 'ui/group/view',
        method: 'POST',
        body: groups,
        params: { viewType },
      }),
      invalidatesTags: ['UncertainViews', 'GroupViews'],
    }),
    fetchUndefinedViewsByFormCode: build.query<GETEntity[], string>({
      query: formCode => ({
        url: `/ui/view/formCode/${formCode}/undefined`,
      }),
      providesTags: ['UndefinedViews'],
    }),
    copyView: build.mutation<GETEntity, POSTCopyEntity>({
      query: viewData => ({
        url: 'ui/view/copy',
        method: 'POST',
        body: viewData,
      }),
      invalidatesTags: ['UncertainViews', 'AllViews'],
    }),
    updateDefaultViewSA: build.mutation<GETEntity, UpdateDefaultViewInSAParams>({
      query: ({ contextId, viewId }) => ({
        url: 'ui/context/default_view',
        method: 'POST',
        body: {
          id: contextId,
          viewId,
        },
      }),
      invalidatesTags: ['DefaultSA'],
    }),
    fetchDefaultViewSA: build.query<GETEntity, GETDefaultViewInSAParams>({
      query: ({ contextId }) => ({
        url: `ui/context/id/${contextId}/default_view`,
        method: 'GET',
      }),
      providesTags: ['DefaultSA'],
    }),
    // Загрузка данных для VIEW
    fetchObjectDataFilterByCodeEnriched: build.mutation<GETObjectData, GETObjectDataParamsEnriched>(
      {
        query: ({
          code,
          page,
          size,
          sort,
          filter,
          viewId,
          contextId,
          sortKeysForQuickSearch,
          searchValue,
          searchRule,
          body,
        }) => ({
          url: `router/storage/object/list/view/${viewId}?${getEnrichedSortParam(sort)}`,
          method: 'POST',
          params: {
            code,
            page,
            size,
            viewId,
            contextId,
            filter,
            searchValue,
            searchRule,
            sortKeysForQuickSearch,
          },
          body,
        }),
        extraOptions: {
          showNotification: false,
        },
        transformResponse: (output: ObjectDataRecord[], meta: FetchBaseQueryMeta) =>
          transformResponseWithPagination<ObjectDataRecord[]>(output, meta),
        // providesTags: ['ObjectData'],
      }
    ),
    // Загрузка данных для ListControl и DropDown
    fetchListControlOrDropdownObjectDataEnriched: build.mutation<
      GETObjectData,
      GETObjectDataParamsEnriched
    >({
      query: ({
        page,
        size,
        sort,
        formObjectCode,
        elementId,
        valueId,
        fieldId,
        dropDownEntityType,
        formObjectId,
        viewId,
        body,
        filter,
        searchValue,
        searchRule,
        sortKeysForQuickSearch,
      }) => ({
        url: `router/storage/object/list/form/dropDown?${getEnrichedSortParam(sort)}`,
        method: 'POST',
        params: {
          viewId,
          dropDownEntityType,
          page,
          size,
          formObjectCode,
          formObjectId,
          elementId,
          valueId,
          fieldId,
          filter,
          searchValue,
          searchRule,
          sortKeysForQuickSearch,
        },
        body,
      }),
      extraOptions: {
        showNotification: false,
      },
      transformResponse: (output: ObjectDataRecord[], meta: FetchBaseQueryMeta) =>
        transformResponseWithPagination<ObjectDataRecord[]>(output, meta),
      // providesTags: ['ObjectData'],
    }),
    fetchObjectDataRecordEnrichedById: build.mutation<
      ObjectDataRecord[],
      GETObjectDataRecordEnrichedParams
    >({
      query: ({ ids, viewId }) => ({
        url: `router/storage/object/list/view/${viewId}/id`,
        params: { ids, viewId },
      }),
    }),
  }),
})

export const {
  useDeleteViewMutation,
  useDeleteViewByCodeMutation,
  useFetchAllViewQuery,
  useFetchAllFormIntegrationViewsQuery,
  useFetchViewQuery,
  useFetchViewByCodeQuery,
  useCreateViewMutation,
  useUpdateViewMutation,
  useFetchAllViewUncertainQuery,
  useFetchViewGroupsQuery,
  useUpdateViewGroupsMutation,
  useFetchUndefinedViewsByFormCodeQuery,
  useCopyViewMutation,
  useUpdateDefaultViewSAMutation,
  useFetchDefaultViewSAQuery,
  useFetchViewByIdNoCacheMutation,
  useFetchObjectDataFilterByCodeEnrichedMutation,
  useFetchObjectDataRecordEnrichedByIdMutation,
  useFetchListControlOrDropdownObjectDataEnrichedMutation,
} = viewApi
