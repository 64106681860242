import { useEffect, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useParams } from 'react-router-dom'

import {
  AlignmentType,
  FormatType,
  GetTextParamsHandle,
  TextSettingsStateType,
} from '@components/TextSettings'

import { useFetchStyleSettingByIdQuery } from '@redux/api/styleSetting.api'

import { ROUTES } from '@constants'

import { GeneralStyleSettingDataFormType } from '../types'

import { useHandlers } from './useHandlers'

export const useStyleSettingsCreateOrEdit = () => {
  const location = useLocation() as unknown as Location & { state?: { id?: string } }
  const { id } = useParams()

  const textSettingsRef = useRef<GetTextParamsHandle>(null)

  const isCreate = ROUTES.CONFIG_STYLE_SETTINGS_CREATE === location.pathname
  const isEdit = !!id

  const methods = useForm<GeneralStyleSettingDataFormType>({
    defaultValues: {
      title: '',
      code: '',
    },
  })

  const { reset } = methods

  const { data: styleSettingById, isLoading: isLoadingStyleSettingById } =
    useFetchStyleSettingByIdQuery(
      { id },
      {
        skip: !id,
      }
    )

  useEffect(() => {
    if (styleSettingById) {
      reset({
        title: styleSettingById.title,
        code: styleSettingById.code,
      })
    }
  }, [styleSettingById])

  const initialTextSettings: TextSettingsStateType | undefined = useMemo(() => {
    if (!styleSettingById || !styleSettingById.settingText) {
      return undefined
    }

    const { fontFamily, fontColor, alignment, fontSize, isBold, isItalic } =
      styleSettingById.settingText

    const { color: borderColor } = styleSettingById.settingBorder

    return {
      fontFamily: { id: fontFamily, label: fontFamily },
      fontColor,
      alignment: alignment as AlignmentType,
      fontSize: `${fontSize}`,
      formats: [isBold ? 'bold' : '', isItalic ? 'italic' : ''].filter(
        format => format !== ''
      ) as FormatType[],
      bgFontColor: styleSettingById.background,
      borderColor,
    }
  }, [styleSettingById])

  const { state, data, handlers } = useHandlers({
    methods,
    textSettingsRef,
    initialTextSettings,
  })

  return {
    data: {
      ...data,
      methods,
      isCreate,
      isEdit,
      id,
      textSettingsRef,
      isLoadingStyleSettingById,
      initialTextSettings,
    },
    state: { ...state },
    handlers: { ...handlers },
  }
}
