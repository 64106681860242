import { FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react'

import { getEnrichedSortParam, transformResponseWithPagination } from '@helpers'
import {
  GETAllObject,
  GETEnumForObject,
  GETObjectData,
  GETObjectDataParamsEnriched,
  GETObjectDataRecordParams,
  GETObjectEnumParams,
  GETObjectFieldChanges,
  GETObjectFieldChangesParams,
  GETObjectModel,
  ObjectDataRecord,
  ObjectFieldChangesDTO,
} from '@types'

import { commonAPI } from './common.api'

export const objectAPI = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchAllObject: build.query<GETAllObject, void>({
      query: () => ({
        url: 'router/models/object/list',
      }),
    }),
    fetchObjectByCode: build.query<GETObjectModel, string | void>({
      query: code => ({
        url: `router/models/object/code/${code}`,
      }),
    }),
    fetchEnumForObject: build.query<GETEnumForObject[], GETObjectEnumParams>({
      query: ({ code, name }) => ({
        url: `router/models/object/code/${code}/field/code/${name}/enums`,
      }),
    }),
    // TODO Устарело, проверить
    fetchObjectData: build.mutation<ObjectDataRecord, GETObjectDataRecordParams>({
      query: ({ objectCode, objectId, expands }) => ({
        url: `router/storage/object/${objectCode}/id/${objectId}`,
        params: { expands },
      }),
    }),
    fetchObjectFieldChanges: build.mutation<GETObjectFieldChanges, GETObjectFieldChangesParams>({
      query: ({ objectCode, objectId, page, size, sort, filter }) => ({
        url: `router/audit/object/${objectCode}/id/${objectId}`,
        params: {
          page,
          size,
          sort,
          filter,
        },
      }),
      // providesTags: ['AuditData'],
      transformResponse: (output: ObjectFieldChangesDTO[], meta: FetchBaseQueryMeta) =>
        transformResponseWithPagination<ObjectFieldChangesDTO[]>(output, meta),
    }),
    fetchListControlOrDropdownObjectDataEnriched: build.mutation<
      GETObjectData,
      GETObjectDataParamsEnriched
    >({
      query: ({
        page,
        size,
        sort,
        formObjectCode,
        elementId,
        valueId,
        fieldId,
        dropDownEntityType,
        formObjectId,
        viewId,
        body,
        filter,
        searchValue,
        searchRule,
        sortKeysForQuickSearch,
      }) => ({
        url: `router/storage/object/list/form/dropDown?${getEnrichedSortParam(sort)}`,
        method: 'POST',
        params: {
          viewId,
          dropDownEntityType,
          page,
          size,
          formObjectCode,
          formObjectId,
          elementId,
          valueId,
          fieldId,
          filter,
          searchValue,
          searchRule,
          sortKeysForQuickSearch,
        },
        body,
      }),
      extraOptions: {
        showNotification: false,
      },
      transformResponse: (output: ObjectDataRecord[], meta: FetchBaseQueryMeta) =>
        transformResponseWithPagination<ObjectDataRecord[]>(output, meta),
      // providesTags: ['ObjectData'],
    }),
    fetchObjectByCodeNoCache: build.mutation<GETObjectModel, string>({
      query: code => ({
        url: `router/models/object/code/${code}`,
      }),
    }),
  }),
})

export const {
  useFetchAllObjectQuery,
  useFetchObjectByCodeQuery,
  useFetchEnumForObjectQuery,
  useFetchObjectFieldChangesMutation,
  useFetchObjectDataMutation,
  useFetchObjectByCodeNoCacheMutation,
} = objectAPI
