import { FC, useContext, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import { FormGenerator } from '@microservices/wiskey-react-components'
import { AutocompleteOption } from '@microservices/wiskey-react-components/dist/AutoComplete'
import { FormInputsType } from '@microservices/wiskey-react-components/dist/types/forms'

import { PageObjectStatesContext } from '@pages/ObjectsStatesCreateOrEdit'
import {
  ObjectStateValueShotType,
  ObjectStateValueType,
} from '@pages/ObjectsStatesCreateOrEdit/types'

import { ModalWrapper } from '@components/ModalWrapper'

import { useFetchAllStyleSettingsQuery } from '@redux/api/styleSetting.api'

import { GENERATOR_INPUT_TYPE, MODAL_TYPE } from '@constants'

type ObjectStateValueFormType = {
  fieldValue: string
  styleSetting: AutocompleteOption<string | number> | null
}

const objectStateDefaultValue: ObjectStateValueFormType = {
  fieldValue: '',
  styleSetting: null,
}

export const AddObjectStateValueDialog: FC = () => {
  const { t } = useTranslation()

  const {
    showDialog,
    handleCloseModal,
    modalType,
    objectStateId,
    handleSetFields,
    editableFieldValue,
    handleSetShowDialog,
    fields,
  } = useContext(PageObjectStatesContext)

  const objectStateDefaultValue: ObjectStateValueShotType = {
    id: undefined,
    fieldValue: '',
    objectStateId,
    styleSettingId: undefined,
    styleSetting: {
      code: '',
      label: '',
    },
  }

  const methods = useForm<ObjectStateValueShotType>({
    defaultValues: objectStateDefaultValue,
  })

  const {
    handleSubmit,
    watch,
    formState: { isDirty },
    reset,
  } = methods

  const watchFieldValue = watch('fieldValue')
  const watchStyleSetting = watch('styleSetting')
  const isEdit = modalType === MODAL_TYPE.EDIT

  useEffect(() => {
    if (!isEdit) {
      reset(objectStateDefaultValue)
    } else if (isEdit && editableFieldValue) {
      reset({
        id: editableFieldValue.id,
        fieldValue: editableFieldValue.fieldValue,
        objectStateId,
        styleSettingId: editableFieldValue?.styleSetting?.id ?? editableFieldValue?.styleSettingId,
        styleSetting: {
          id: editableFieldValue?.styleSetting?.id ?? editableFieldValue?.styleSettingId,
          label: editableFieldValue?.styleSetting?.title ?? editableFieldValue?.styleSettingsTitle,
        },
      })
    }
  }, [isEdit])

  const { data: styleSettingsData, isLoading: isLoadingStyleSettingsData } =
    useFetchAllStyleSettingsQuery({}, { refetchOnMountOrArgChange: true })

  const autocompleteOptionsStyleSettings = useMemo(() => {
    if (styleSettingsData) {
      return styleSettingsData.map(style => ({ id: style.id, label: style.title }))
    }
  }, [styleSettingsData])

  const handleSave = (data: ObjectStateValueType) => {
    const { id } = data

    const objectStateValue: ObjectStateValueType = {
      id: !isEdit ? uuid() : id,
      fieldValue: watchFieldValue,
      objectStateId: objectStateId ? objectStateId : undefined,
      styleSettingId: watchStyleSetting?.id,
      styleSettingsTitle: watchStyleSetting?.label,
    }

    handleSetFields(objectStateValue, Boolean(id))
    handleSetShowDialog(false)
  }

  const fieldInputs: FormInputsType[] = [
    {
      name: 'fieldValue',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      placeholder: t('placeholder.fieldValue'),
      label: t('label.fieldValue'),
      disabled: isEdit,
      rules: {
        required: true,
        validate: value => {
          if (!isEdit && fields.find(field => field.fieldValue === value)) {
            return `${value} ${t('objectsStates.errors.alreadyUsed')}`
          }
        },
      },
    },
    {
      name: 'styleSetting',
      inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
      placeholder: t('placeholder.styleSettings'),
      label: t('label.styleSettings'),
      loading: isLoadingStyleSettingsData,
      rules: {
        required: true,
      },
      autocompleteOptions: autocompleteOptionsStyleSettings,
    },
  ]

  return (
    <FormProvider {...methods}>
      {showDialog && (
        <ModalWrapper
          disabledSave={!isDirty}
          isShow={showDialog}
          title={t(`objectsStates.titleField.${modalType}`)}
          onClose={() => handleCloseModal(isDirty)}
          onSave={handleSubmit(handleSave)}
        >
          <FormGenerator inputs={fieldInputs} />
        </ModalWrapper>
      )}
    </FormProvider>
  )
}
